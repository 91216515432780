import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "shop-2"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Muscle Shirt`}</h4>
    <p><img parentName="p" {...{
        "src": "/files/gift-certificate.jpg",
        "alt": "Image"
      }}></img></p>
    <h4>{`Refrigerator Magnet`}</h4>
    <p><img parentName="p" {...{
        "src": "/files/gift-certificate.jpg",
        "alt": "Image"
      }}></img></p>
    <h4>{`Gift Certificate ($10)`}</h4>
    <p><img parentName="p" {...{
        "src": "/files/gift-certificate.jpg",
        "alt": "Image"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      